import React from 'react'

export default function SectionHeader({ title, subtitle, noUnderline }) {
  /**
   * Expects props to have
   *   .survey
   * Options props
   *   .onClose – should be set if being rendered by survey-view
   *              shouldn't be set if rendered by home
   */
  let titlePart1 = title.split(' ').slice(0, -2).join(' ')

  if (titlePart1) {
    titlePart1 += ' '
  }

  const titlePart2 = title.split(' ').slice(-2).join(' ')

  return (
    <div className="sectionHeader is-family-primary">
      <h1 className="is-size-3-tablet is-size-4-mobile">
        {titlePart1}
        <span className={noUnderline ? '' : 'greenUnderline'}>{titlePart2}</span>
      </h1>
      <p className="is-size-6">{subtitle}</p>
    </div>
  )
}

// SectionHeader.propTypes = {
//   previewImage: PropTypes.string,
//   title: PropTypes.string,
//   details: PropTypes.string,
//   price: PropTypes.number,
//   buttonText: PropTypes.string,
//   onClick: PropTypes.func,
//   buttonCallback: PropTypes.func,
//   short: PropTypes.bool
// };
