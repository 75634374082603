import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import mixpanel from 'mixpanel-browser'
import Button from './Button'
import PreviewCompatibleImage from '../../PreviewCompatibleImage'

export default class ProductCard extends React.Component {
  /**
   * Clips survey author's name if it's too long
   * for mobile view.
   */
  maxAuthorName(text) {
    text = text || ''
    let width = window.innerWidth
    if (width <= 568 && text.length >= 10) return text.substring(0, 10) + '...'
    else if (width <= 812 && text.length >= 16) return text.substring(0, 16) + '...'
    else if (width <= 1112 && text.length >= 20) return text.substring(0, 20) + '...'
    else if (width <= 1200 && text.length >= 25) return text.substring(0, 25) + '...'
    return text
  }

  onClick = () => {
    mixpanel.track('Marketplace Card Click', {
      title: this.props.title,
      section: this.props.section,
    })
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  /**
   * Expects props to have
   *   .survey
   * Options props
   *   .onClose – should be set if being rendered by survey-view
   *              shouldn't be set if rendered by home
   */
  render() {
    return (
      <div
        className={`column is-full-mobile ${
          this.props.long ? 'long is-two-thirds' : 'is-one-third'
        } `}
      >
        <div className="productCard" onClick={this.onClick}>
          {this.props.previewImage.image && this.props.previewImage.image.childImageSharp ? (
            <div className="imageContainer">
              <PreviewCompatibleImage imageInfo={this.props.previewImage} />
            </div>
          ) : (
            <div
              className="imageContainer"
              style={{
                backgroundImage: `url(${this.props.previewImage.image})`,
              }}
            />
          )}
          <div className="bodyContainer">
            <h3 className="is-family-primary is-size-4 has-text-weight-semibold">
              {this.props.title}
            </h3>
            <p className="is-family-primary is-size-6">{this.props.details}</p>
            {false && !this.props.hideLearnMore && (
              <Link to={`/products/insights/${this.props._id}`}>Learn More</Link>
            )}
          </div>
          {this.props.customBottomRow || (
            <div className="bottomRow">
              {/*this.props.price === 0 ? (
                <div className="priceContainer">
                  <h3>FREE</h3>
                </div>
              ) : (
                <div className="priceContainer">
                  Starting at
                  <h3>${this.props.price}</h3>
                </div>
              )/*}
              {/*TODO: dynamic based on logged in / unpurchased / purchased */}
              <Button text={this.props.buttonText} height="100%" width="37%" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

ProductCard.propTypes = {
  previewImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  route: PropTypes.string,
  details: PropTypes.string,
  price: PropTypes.number,
  buttonText: PropTypes.string,
  buttonCallback: PropTypes.func,
  long: PropTypes.bool,
}
