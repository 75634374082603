import React from 'react'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import { toggleHubspotModalSolutions } from '../../../redux/actions/modals'
import ProductCard from '../common/ProductCard'
import SectionHeader from '../common/SectionHeader'

const Playbooks = ({ title, subtitle, solutions, toggleHubspotModalSolutions, preview }) => (
  <div className="productContainer">
    <div className="mainContent">
      <SectionHeader title={title} subtitle={subtitle} />
      <div className="columns is-multiline is-variable is-6-tablet is-0-mobile topMargin">
        <ProductCard
          title="2019-2020 Campus Recruiting Playbook"
          details="The comprehensive guide to recruiting college students, based on responses of over 15,000 students at top US universities."
          price={150}
          buttonText="Get Started"
          previewImage={{ image: solutions[3].image, alt: title }}
          key={title}
          onClick={() => navigate('/marketplace/playbooks/sample')}
          section="solutions"
        />
      </div>
      <div className="divider" />
      <div className="columns is-multiline is-variable is-6-tablet is-0-mobile topMargin">
        {solutions.map(({ title, description, image, long }) => (
          <ProductCard
            title={title}
            details={description}
            price={150}
            buttonText="Get Started"
            previewImage={{ image, alt: title }}
            key={title}
            long={long}
            onClick={toggleHubspotModalSolutions}
            section="solutions"
          />
        ))}
      </div>
    </div>
  </div>
)

export default connect(() => ({}), {
  toggleHubspotModalSolutions,
})(Playbooks)
