import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Playbooks from '../components/marketplace/playbooks'

export default class PlaybooksPage extends React.Component {
  render() {
    // return isSignedIn() ? <LoggedIn /> : <LoggedOut />
    // const { data } = this.props;
    // const DIYSurveyImage = data.wideImages.childImageSharp.fluid;
    const { data } = this.props
    const { frontmatter } = data.markdownRemark
    return (
      <Layout active="playbooks">
        <Playbooks {...frontmatter} />
      </Layout>
    )
  }
}

export const playbooksPageQuery = graphql`
  query PlaybooksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        solutions {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, maxHeight: 683, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
          long
        }
      }
    }
  }
`
